<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      POConfirmation
                    </h5>
                    <p class="text-sm text-body mb-0"></p>
                  </div>
                </div>
              </div>

              <v-card-text>
                <v-form ref="frm">
                  <v-card-text class="px-0 py-0">
                    <v-row>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >From (Start date)</label
                        >
                        <v-menu
                          ref="fromDate_menu"
                          v-model="fromDate_menu"
                          :close-on-content-click="false"
                          :return-value.sync="fromDate_menu"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.fromDate"
                              v-bind="attrs"
                              v-on="on"
                              hide-details="auto"
                              class="
                                input-style
                                font-size-input
                                text-light-input
                                placeholder-light
                                input-icon
                                mt-5
                              "
                              dense
                              flat
                              filled
                              solo
                              height="46"
                              placeholder="Start Date"
                              persistent-hint
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            color="green lighten-1"
                            header-color="primary"
                            v-model="editedItem.fromDate"
                            :max="editedItem.toDate"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="fromDate_menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.fromDate_menu.save(editedItem.fromDate)
                              "
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span>To
                          (End date)</label
                        >
                        <v-menu
                          ref="toDate_menu"
                          v-model="toDate_menu"
                          :close-on-content-click="false"
                          :return-value.sync="toDate_menu"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.toDate"
                              v-bind="attrs"
                              v-on="on"
                              hide-details="auto"
                              class="
                                input-style
                                font-size-input
                                text-light-input
                                placeholder-light
                                input-icon
                                mt-5
                              "
                              dense
                              flat
                              filled
                              solo
                              height="46"
                              placeholder="To Date"
                              persistent-hint
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            color="green lighten-1"
                            header-color="primary"
                            v-model="editedItem.toDate"
                            :min="editedItem.fromDate"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="toDate_menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.toDate_menu.save(editedItem.toDate)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions dense class="card-padding d-flex justify-end">
                    <!-- <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-secondary
                        bg-light
                        px-6
                      "
                      >Clear</v-btn
                    > -->

                    <v-btn
                      @click="search()"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      dark
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-success
                        px-6
                      "
                      >Search</v-btn
                    >
                  </v-card-actions>
                </v-form>
              </v-card-text>

              <v-card-text class="px-0 py-0" v-if="items.length > 0">
                <v-data-table
                  :headers="headers"
                  :items="items"
                  class="table element"
                  :footer-props="{
                    'items-per-page-options': [100, 200, 500, -1],
                  }"
                  mobile-breakpoint="0"
                >
                  <template v-slot:[`item.sale_date`]="{ item }">
                    <span>
                      {{ formatDate(item.sale_date) }}
                    </span>
                  </template>
                  <template v-slot:[`item.po_gen_status`]="{ item }">
                    <v-chip
                      label
                      small
                      :ripple="false"
                      :color="statusColor(item.po_gen_status)"
                    >
                      {{ item.po_gen_status }}
                    </v-chip>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-row>
                      <v-col>
                        <v-btn
                          :ripple="false"
                          icon
                          text
                          width="auto"
                          class="
                            pe-4
                            ps-0
                            mb-0
                            text-default text-capitalize
                            icon-move-right
                            align-center
                          "
                          @click="onNavigate(item)"
                        >
                          View
                          <v-icon right dark size="12" class="ms-0 mt-1">
                            fa fa-arrow-right
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-card-text>
              <!-- <v-card-actions class="card-padding">
                <v-row>
                  <v-col cols="6" lg="3" class="d-flex align-center">
                    <span class="text-body me-3 text-sm">Items per page:</span>
                    <v-text-field
                      hide-details
                      type="number"
                      outlined
                      min="-1"
                      max="15"
                      background-color="rgba(255,255,255,.9)"
                      color="rgba(0,0,0,.6)"
                      light
                      :value="itemsPerPage"
                      @input="itemsPerPage = parseInt($event, 10)"
                      placeholder="Items per page"
                      class="
                        font-size-input
                        placeholder-lighter
                        text-color-light
                        input-alternative input-focused-alternative input-icon
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="ml-auto d-flex justify-end">
                    <v-pagination
                      prev-icon="fa fa-angle-left"
                      next-icon="fa fa-angle-right"
                      class="pagination"
                      color="#38bd34"
                      v-model="page"
                      :length="pageCount"
                      circle
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-card-actions> -->
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- Modal Components::Begin -->

    <!-- <new-blend-plan :dialog.sync="dialog" :data="editedItem" @onSave="onSave" /> -->

    <!-- Modal Components::End -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../api";
// import NewPOConfirmationPlan from "../components/NewPOConfirmationPlan.vue";
export default {
  // components: { NewPOConfirmationPlan },
  name: "POConfirmation",
  data() {
    return {
      overlay: false,
      items: [],
      dialog: false,
      headers: [
        { text: "Vendor Name", width: "200", value: "vendor_name" },
        {
          text: "Warehouse name",
          width: "200",
          value: "company_warehouse_name",
        },
        { text: "PO", width: "200", value: "po_number" },
        { text: "Sale date", width: "200", value: "sale_date" },
        { text: "Status", width: "200", value: "po_gen_status" },
        { text: "Actions", width: "80", value: "actions" },
      ],
      editedItem: {
        fromDate: null,
        toDate: null,
      },
      defaultItem: {
        fromDate: null,
        toDate: null,
      },
      fromDate_menu: false,
      toDate_menu: false,
      // Pagination
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.editedItem = Object.assign(this.defaultItem, {});
      }
    },
  },
  computed: {
    ...mapGetters({
      currentPage: "getCurrentPage",
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.items = [];
      const date = new Date();
      this.editedItem.fromDate = this.formatDate(
        new Date(date.getFullYear(), date.getMonth(), 1)
      );
      this.editedItem.toDate = this.formatDate(
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
      );
      this.items = await api.getPO(this.editedItem);
    },
    async search() {
      this.overlay = true;
      this.items = await api.getPO(this.editedItem);
      if (this.items.length == 0) {
        this.noDataFoundAlert("No Data Found...");
      }
      this.overlay = false;
    },
    noDataFoundAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },

    statusColor(status) {
      if (status === "PENDING") {
        return "rgba(255, 110, 64, 0.5)";
      }
      if (status === "APPROVED") {
        return "rgba(105, 240, 174, 0.5)";
      }
      if (status === "CANCELLED") {
        return "rgba(237, 63, 59, 0.5)";
      }
    },

    onNavigate(item) {
      this.$router.push({
        name: "PO Confirmation Details",
        params: { poConfirmation: item, currentPage: this.currentPage },
      });
    },
  },
};
</script>

<style scoped>
.element .v-data-table__wrapper::-webkit-scrollbar {
  width: 24px;
  height: 8px;
  background-color: #143861;
}
</style>
